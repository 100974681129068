<template>
  <div class="common-department">
    <confirm-modal ref="confirmModal" />
    <!-- sticky action menu bar -->
    <div :class="getTableHeaderClass + ' action-menu'" :style="{top: headerHeight + 'px'}">
      <div class="user-infor">
        <span>{{ countryAndLanguage }}</span>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light topnav">
        <div id="navbarText" :class="getTableHeaderClass + ' collapse navbar-collapse'">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <router-link exact class="nav-link pr-0 pl-0" to="/recommendation">
                Persona
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                exact
                class="nav-link pr-0 pl-0"
                to="/recommendation/rcmd"
              >
                Recommendation
              </router-link>
            </li>
          </ul>
          <!------------- edit range-------------------------->
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <template v-if="!editable">
                <b-button
                  variant="outline-info custom-btn"
                  class="btn-blue"
                  @click="turnOnEditable()"
                >
                  Edit content
                </b-button>
              </template>
              <template v-else>
                <b-button
                  variant="outline-info custom-btn btn-save"
                  class="btn-blue"
                  @click="save()"
                >
                  Save
                </b-button>
                <b-button
                  variant="outline-info custom-btn"
                  class="btn-blue"
                  @click="turnOffEditable()"
                >
                  Cancel
                </b-button>
              </template>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <router-view ref="childComponent" />
    <!-- Space end page -->
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { MODAL_CANCEL, MODAL_SAVE, OK_CONFIRM } from "@/common/CommonUtils";
import ConfirmModal from "../common/ConfirmModal.vue";

export default {
  components: {
    ConfirmModal,
  },
  data() {
    return {
      edit: false,
      icoating: false,
      under45: true,
      idx: 1,
      editable: (this.$store.state.survey.isSurveyEditing = false) && false
    };
  },

  computed: {
    countryAndLanguage() {
      return this.$store.state.survey.countryAndLanguage;
    },
    headerHeight() {
      return this.$store.state.common.theHeaderHeight
    },
    getTableHeaderClass() {
        return this.editable
            ? "action-menu-edit"
            : ""
    },
  },
  methods: {
    changeEditableState(state) {
      this.editable = state;
      this.$store.state.survey.isSurveyEditing = state;
      if (state === true) {
        this.$store.state.survey.editingScreenSaveFunc = this.save;
      }
    },
    turnOnEditable() {
      this.$refs.childComponent.turnOnEditable()
      this.changeEditableState(true)
    },
    turnOffEditable() {
      this.$refs.confirmModal.open(MODAL_CANCEL, (data) => {
        if (data === OK_CONFIRM) {
          this.$refs.childComponent.turnOffEditable()
          this.changeEditableState(false)
        }
      });
    },
    save(chainFunc) {
      this.$refs.confirmModal.open(MODAL_SAVE, (data) => {
        if (data === OK_CONFIRM) {
          this.$refs.childComponent.save(chainFunc)
          this.changeEditableState(false)
        }
      });
    },
  }
};
</script>

<style scoped>
.nav-link {
  font-size: 20px;
}
</style>
